import { ref, computed, watch } from 'vue';

export default function verificationPassword() {
  const passwordUser = ref('');
  const passwordError = ref(false);

  const checkPassword = () => (passwordUser.value.length >= 4 ? true : false);

  watch(passwordUser, () => {
    passwordError.value = false;
  });

  function isPasswordValid() {
    if (!checkPassword()) {
      passwordError.value = true;
      return false;
    } else {
      return true;
    }
  }

  return {
    passwordError,
    passwordUser,
    isPasswordValid
  };
}

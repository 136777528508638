<template lang="pug">
formTitle Acceso
.flex.flex-col.mt-6.mb-4.gap-6
  inputText(
    labelText="Correo electronico",
    placeholder="ejemplo@mail.com",
    v-model="emailUser",
    :errorMessage="emailErrorMessage",
    :showError="emailError"
  )
  inputText(
    inputType="password",
    labelText="Contraseña",
    placeholder="*****",
    v-model="passwordUser",
    :errorMessage="passwordErrorMessage",
    :showError="passwordError"
  )
  ctaButton(textButton="Acceder", @click="login")
  .link(@click="$emit('changeTab', 'remember')") ¿Has olvidado la contraseña?
absoluteLoader(:show="showLoader")
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import verificationEmail from "@/app/composables/verificarionEmail";
import verificationPassword from "@/app/composables/verificationPassword";
import { AuthRepository } from "@/app/repository/auth.repository";
export default {
  name: "access",
  emits: ["changeTab", "closeLogin"],
  setup(_, { emit }) {
    const ErrorEmailMessage1 = "El correo no es correcto";
    const ErrorEmailMessage2 = "Cuenta deshabilitada.";
    const ErrorEmailMessage3 = "Esta cuenta no existe.";
    const emailErrorMessage = ref(ErrorEmailMessage1);
    const passwordErrorMessage1 = "Contraseña no valida";
    const passwordErrorMessage2 = "Contraseña incorrecta";
    const passwordErrorMessage = ref(passwordErrorMessage1);
    const showLoader = ref(false);
    const store = useStore();

    const { emailUser, emailError, isEmailValid } = verificationEmail();
    const { passwordUser, passwordError, isPasswordValid } =
      verificationPassword();

    const login = async () => {
      if (isEmailValid() && isPasswordValid()) {
        showLoader.value = true;
        await AuthRepository.login(emailUser.value, passwordUser.value)
          .then((result) => {
            store.commit("authStore/loggedIn", { token: result.access_token });
            emit("closeLogin");
          })
          .catch((error) => {
            if (error.response.data.message == "password_error")
              errorLoginPassword();
            if (error.response.data.message == "user_not_exists")
              errorLoginNotExists();
            if (error.response.data.message == "user_disabled")
              errorLoginDisabled();
          });
        showLoader.value = false;
      }
    };

    const errorLoginPassword = () => {
      passwordErrorMessage.value = passwordErrorMessage2;
      passwordError.value = true;
    };
    const errorLoginNotExists = () => {
      emailErrorMessage.value = ErrorEmailMessage3;
      emailError.value = true;
    };
    const errorLoginDisabled = () => {
      emailErrorMessage.value = ErrorEmailMessage2;
      emailError.value = true;
    };

    return {
      emailUser,
      emailError,
      emailErrorMessage,
      passwordUser,
      passwordError,
      passwordErrorMessage,
      login,
      showLoader,
    };
  },
};
</script>
<style lang="scss" scoped>
.link {
  @apply text-center text-sm cursor-pointer font-medium text-gray-400;
  &:hover {
    @apply text-green-700;
  }
}
</style>
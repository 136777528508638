import { ApiAxios } from './repository.config';

const RESOURCE = '/auth';

export const AuthRepository = {
  async login(user_email: string, user_password: string) {
    const { data } = await ApiAxios().post(`${RESOURCE}/sign-in`, {
      email: user_email,
      password: user_password
    });
    return data;
  },

  async remember(user_email: string) {
    const { data } = await ApiAxios().post(`${RESOURCE}/remember-password`, {
      remember_email: user_email
    });
    return data;
  }
};

import { ref, computed, watch } from 'vue';
export default function verificationEmail() {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  const emailUser = ref('');
  const emailError = ref(false);

  const emailParsed = computed(() => emailUser.value.trim().toLowerCase());

  watch(emailUser, () => {
    emailError.value = false;
  });

  function isEmailValid() {
    if (!reg.test(emailParsed.value)) {
      emailError.value = true;
      return false;
    } else {
      return true;
    }
  }

  return {
    emailUser,
    emailError,
    isEmailValid
  };
}
